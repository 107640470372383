<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            :loading="isLoadingBarang"
            :disabled="old_status_value !== 'draft'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Barang"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PurchaseInvoice' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.pi_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>No Bukti</label>
                <InputText v-model="form.pi_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-3">
                <label
                  :class="{
                    'p-error': v$.form.inv_no.$invalid && submitted,
                  }"
                  >No Faktur</label
                >
                <InputText
                  v-model="form.inv_no"
                  :class="{
                    'p-invalid': v$.form.inv_no.$invalid && submitted,
                  }"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.inv_no.$invalid && submitted) ||
                    v$.form.inv_no.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.inv_no.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label>Tanggal Faktur</label>
                <Calendar
                  v-model="form.inv_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-12">
                <label>Supplier</label>
                <AutoComplete
                  v-model="form.supplier"
                  :suggestions="acSupplier"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.supplier.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchSupplier($event)"
                  @item-select="selectSupplier"
                >
                  <template #item="slotProps">
                    <div>
                      {{ slotProps.item.nama }}
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <div class="field col-12 md:col-6">
                <label>T.O.P</label>
                <InputText v-model="form.top" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-6">
                <label>Tgl jatuh tempo</label>
                <InputText
                  v-model="form.tgl_jatuh_tempo"
                  class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12">
                <label>Keterangan</label>
                <InputText v-model="form.keterangan" class="w-full" />
              </div>
            </div>
          </div>
          <div class="col-12 lg:col-3 xl:col-4">
            <div class="formgrid grid">
              <div class="col-4 field-checkbox md:mt-5 md:mb-4">
                <Checkbox
                  id="status_bonus"
                  v-model="form.status_bonus"
                  :binary="true"
                  class="mr-1"
                />
                <label for="status_bonus">BONUS</label>
              </div>
              <div class="field col-8">
                <label>PPN</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <Checkbox
                      id="format_ppn"
                      v-model="form.format_12"
                      :binary="true"
                      class="mr-1"
                    />
                  </span>
                  <InputNumber
                    v-model="form.ppn"
                    locale="id-ID"
                    class="w-full"
                    input-class="w-full"
                    @input="onChangePPN"
                  />
                </div>
              </div>
              <div class="field col-12">
                <label
                  :class="{
                    'p-error': v$.form.golongan.$invalid && submitted,
                  }"
                  >Golongan obat</label
                >
                <Dropdown
                  v-model="form.golongan"
                  :options="list_golongan_obat"
                  :disabled="form.id > 0"
                  :class="{
                    'p-invalid': v$.form.golongan.$invalid && submitted,
                  }"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                />
                <small
                  v-if="
                    (v$.form.golongan.$invalid && submitted) ||
                    v$.form.golongan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.golongan.required.$message }}</small
                >
              </div>
              <div class="field col-12">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="form.id === 0"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12">
                <label>Jenis Harga</label>
                <Dropdown
                  v-model="form.jenis_harga"
                  :options="list_jenis_harga"
                  optionValue="value"
                  optionLabel="label"
                  class="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <grid-purchase-invoice-detail
          :items="form.obat"
          :loading="isLoading"
          :show-button="old_status_value === 'draft'"
          :diskon="form.diskon_tambahan"
          :jenis-harga="form.jenis_harga"
          :nilai-pph="form.pph"
          :nilai-materai="form.materai"
          :ppn="form.ppn"
          :format-12="form.format_12"
          :pph-config="pph_config"
          :isEdit="true"
          @edit="onEditData"
          @delete="onConfirmDeletion"
          @diskon="onUpdateDiskon"
          @pph="onUpdatePPH"
          @materai="onUpdateMaterai"
        />
      </div>
    </form>
    <Dialog
      header="Tambah Barang"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-purchase-invoice-barang
        :purchase-order="list_purchase_order"
        :exclude-list="list_produk"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Hapus item dari faktur ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Item <strong>{{ po.nama_obat }}</strong> akan dihapus dari faktur.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeletePO"
        />
      </template>
    </Dialog>
    <Dialog
      header="Edit harga"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <form-harga :item="formEdit" @save="onUpdateHarga" />
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import FormPurchaseInvoiceBarang from '@/components/pembelian/FormPurchaseInvoiceBarang'
import FormHarga from '@/components/pembelian/FormHarga'
import GridPurchaseInvoiceDetail from '@/components/pembelian/GridPurchaseInvoiceDetail'
import GolonganObatService from '@/services/GolonganObatService'
import Hotkey from '@/components/Hotkey'
import PurchaseOrderService from '@/services/PurchaseOrderService'
import PurchaseInvoiceService from '@/services/PurchaseInvoiceService'
import SupplierService from '@/services/SupplierService'
import SettingService from '@/services/SettingService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridPurchaseInvoiceDetail,
    FormPurchaseInvoiceBarang,
    Hotkey,
    FormHarga,
  },
  created() {
    this.supplierService = new SupplierService()
    this.purchaseInvoiceService = new PurchaseInvoiceService()
  },
  data() {
    return {
      submitted: false,
      dialogAdd: false,
      dialogHapus: false,
      dialogEdit: false,
      isLoading: false,
      isLoadingBarang: false,
      isLoadingSaveHeader: false,
      isPPNDisabled: false,
      acSupplier: [],
      supplierService: null,
      purchaseInvoiceService: null,
      po: null,
      deleted: [],
      old_status_value: 'draft',
      list_purchase_order: null,
      list_golongan_obat: null,
      list_jenis_harga: [
        { value: 1, label: 'Termasuk Pajak' },
        { value: 2, label: 'Belum Termasuk Pajak' },
        { value: 3, label: 'Non Pajak' },
      ],
      pph_config: 0,
      form: {
        id: 0,
        inv_at: new Date(),
        pi_at: new Date(),
        pi_no: '',
        top: 0,
        tgl_jatuh_tempo: null,
        golongan: null,
        supplier: null,
        supplier_id: 0,
        jenis_harga: 2,
        diskon_tambahan: 0,
        status: 'draft',
        pph: 0,
        materai: 0,
        obat: [],
        ppn: 0,
        format_12: false,
        status_bonus: false,
      },
      formEdit: {},
    }
  },
  mounted() {
    const self = this
    Promise.all([this.getGolonganObat()]).then(function (result) {
      self.list_golongan_obat = result[0]
    })
    const settingService = new SettingService()
    settingService.get('?filter[key]=PPN').then((res) => {
      this.form.ppn = res.data.data.value
    })
    settingService.get('?filter[key]=PPH').then((res) => {
      this.pph_config = res.data.data.value
    })
    if (this.id > 0) {
      this.isLoading = true
      this.purchaseInvoiceService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.format_12 = res.data.data.format_12 == 0 ? false : true
            this.form.pph = res.data.data.pph
            this.form.materai = res.data.data.materai
            this.form.inv_at = this.form.inv_at
              ? new Date(this.form.inv_at)
              : null
            this.form.pi_at = this.form.pi_at ? new Date(this.form.pi_at) : null
            this.form.golongan = res.data.data.golongan.id
            this.form.supplier = res.data.data.supplier
            this.form.tgl_jatuh_tempo = dayjs(this.form.pi_at)
              .add(this.form.top, 'day')
              .format('DD/MM/YYYY')
            this.old_status_value = this.form.status
            let obat = res.data.data.obat
            obat.forEach((el) => {
              el.diskon_harga =
                el.diskon_harga > 0
                  ? el.diskon_harga
                  : el.diskon > 0
                  ? el.harga * (el.diskon / 100)
                  : 0
            })
            this.form.obat = obat
          }
        })
        .catch((err) => {
          errorHandler(err, 'Purchase Order', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  computed: {
    list_produk() {
      return this.form.obat.map((x) => x.id)
    },
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'open', label: 'Open', level: 1 },
        { value: 'pend', label: 'Pending', level: 2 },
        { value: 'done', label: 'Done', level: 3 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
  },
  methods: {
    async getGolonganObat() {
      const golonganObatService = new GolonganObatService()
      return await golonganObatService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data golongan obat', this)
        })
    },
    add() {
      let golongan = null

      if (!this.form.supplier || !this.list_golongan_obat) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Faktur pembelian',
          detail: 'Supplier & golongan obat harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      const obat = this.list_golongan_obat.find(
        (e) => e.id === this.form.golongan
      )

      if (obat) {
        golongan = obat.nama
      }

      const statusBonusFilter = this.form.status_bonus ? 1 : 0

      const purchaseOrderService = new PurchaseOrderService()
      const self = this
      self.isLoadingBarang = true
      purchaseOrderService
        .get(
          `?filter[golongan]=${golongan}&filter[supplier_id]=${this.form.supplier.id}&filter[has_invoice]=0&filter[status]=rcvd&filter[status_bonus]=${statusBonusFilter}`
        )
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Purchase Order',
              detail: `Purchase order untuk pelanggan ${this.form.supplier.nama} tidak ditemukan !.`,
              life: 3000,
            })
            self.isLoadingBarang = false
          } else {
            self.list_purchase_order = res.data.data
            self.dialogAdd = true
            self.isLoadingBarang = false
            self.list_purchase_order.forEach((el) => {
              let obat = el.obat
              obat.forEach((o) => {
                o.status_bonus = el.status_bonus
                /*
                if (o.history_harga && o.history_harga.length > 0) {
                  o.harga = o.history_harga[o.history_harga.length - 1].harga
                }
                */
                o.harga = el.status_bonus ? 0 : o.harga
                //console.log('history_harga', o.history_harga)
              })
            })
          }
        })
        .catch(() => {
          self.isLoadingBarang = false
        })
    },
    onEditData(item) {
      if (item.diskon > 0) {
        item.diskon = item.diskon > 100 ? 100 : item.diskon
        item.harga_diskon = item.harga - item.harga * (item.diskon / 100)
      } else {
        item.harga_diskon = item.harga - item.diskon_harga
      }
      item.harga_total = Math.round(item.quantity * item.harga_diskon)
      this.formEdit = Object.assign({}, item)
      console.log('item', item)
      this.dialogEdit = true
    },
    onConfirmDeletion(item) {
      this.po = Object.assign({}, item)
      this.dialogHapus = true
    },
    onUpdateDiskon(item) {
      this.form.diskon_tambahan = item
    },
    onUpdatePPH(item) {
      this.form.pph = item
    },
    onUpdateMaterai(item) {
      this.form.materai = item
    },
    onDeletePO() {
      if (this.po.id !== 0) {
        this.deleted.push(this.po.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.po.id && el.obat_id === this.po.obat_id
      )
      this.form.obat.splice(index, 1)
      this.po = {}
      this.dialogHapus = false
    },
    save(isFormValid) {
      this.submitted = true

      // Quantity Check
      /*
      this.form.obat.forEach((item) => {
        if (
          item.batch.reduce((tot, el) => tot + el.quantity, 0) !== item.quantity
        ) {
          isFormValid = false
          this.$toast.add({
            severity: 'error',
            summary: 'Faktur pembelian',
            detail: 'Quantity Tidak Sesuai',
            life: 3000,
          })
        }
      })
      */

      if (!isFormValid) {
        return
      }

      let form = {}

      let obat = []
      this.form.obat.forEach((el) => {
        if (el.pod_id) {
          obat.push({
            id: el.id,
            keterangan: !el.keterangan ? '' : el.keterangan,
            quantity: el.quantity,
            harga: el.harga,
            diskon: el.diskon,
            diskon_harga: el.diskon ? 0 : el.diskon_harga,
          })
        } else {
          obat.push({
            id: 0,
            pod_id: el.id,
            keterangan: !el.keterangan ? '' : el.keterangan,
            quantity: el.quantity,
            harga: el.harga,
            diskon: el.diskon,
            diskon_harga: el.diskon ? 0 : el.diskon_harga,
          })
        }
      })

      form.id = this.form.id
      form.status = this.form.status
      form.pi_at = this.form.pi_at
        ? dayjs(this.form.pi_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.inv_at = this.form.inv_at
        ? dayjs(this.form.inv_at).format('YYYY-MM-DD HH:mm:ss')
        : null
      form.inv_no = this.form.inv_no
      form.jenis_harga = this.form.jenis_harga
      form.diskon_tambahan = this.form.diskon_tambahan == null ? 0 : this.form.diskon_tambahan
      form.pph = this.form.pph
      form.ppn = this.form.ppn
      form.format_12 = this.form.format_12
      form.materai = this.form.materai == null ? 0 : this.form.materai
      form.obat = obat

      if (!form.diskon_tambahan) form.diskon_tambahan = 0

      form.status_bonus = this.form.status_bonus // ? 1 : 0

      form.pi_no = ''
      if (this.form.pi_no) {
        form.pi_no = this.form.pi_no
      }
      if (this.form.supplier) {
        form.supplier_id = this.form.supplier.id
      }
      if (this.form.golongan) {
        form.golongan_id = this.form.golongan
      }
      if (this.form.keterangan) {
        form.keterangan = this.form.keterangan
      }

      if (this.form.id === 0) {
        delete form.id
        this.isLoadingSaveHeader = true
        this.purchaseInvoiceService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Faktur pembelian',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.$router.push({ name: 'PurchaseInvoice' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Faktur pembelian', this)
          })
          .finally(() => {
            this.isLoadingSaveHeader = false
            this.refreshDetail()
          })
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.purchaseInvoiceService
          .update(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Faktur Pembelian',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Faktur pembelian', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
            this.refreshDetail()
          })
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.purchaseInvoiceService
          .get(`/${this.id}`)
          .then((res) => {
            if (res.data.status === 200) {
              let obat = res.data.data.obat
              obat.forEach((el) => {
                el.diskon_harga =
                  el.diskon_harga > 0
                    ? el.diskon_harga
                    : el.diskon > 0
                    ? el.harga * (el.diskon / 100)
                    : 0
              })
              this.form.obat = obat
            }
          })
          .catch((err) => {
            errorHandler(err, 'Purchase Invoice (Obat)', this)
          })
      }
    },
    onSaveBarang(item) {
      item.forEach((el) => {
        el.quantity = el.quantity_actual
        this.form.obat.push(el)
      })
      this.dialogAdd = false
    },
    onCloseFormBarang() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    searchSupplier(e) {
      this.supplierService
        .get('?filter[nama]=' + e.query + '&filter[status]=aktif')
        .then((res) => {
          this.acSupplier = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    selectSupplier(e) {
      this.form.top = e.value.top
      this.form.supplier = e.value
      this.form.supplier_id = e.value.id
      this.form.tgl_jatuh_tempo = dayjs(this.form.pi_at)
        .add(e.value.top, 'day')
        .format('DD/MM/YYYY')
    },
    onUpdateHarga(item) {
      item.diskon_harga = item.diskon
        ? item.harga * (item.diskon / 100)
        : item.diskon_harga
      const index = this.form.obat.findIndex(
        (el) =>
          el.id === item.id &&
          el.obat_id === item.obat_id &&
          el.po_id === item.po_id
      )

      this.form.obat[index] = item
      this.dialogEdit = false
    },
    onChangePPN(e) {
      this.form.ppn = e.value
    },
    /*
    getListStatus() {
      let status = []
      if (this.old_status_value === '') {
        status = [{ value: 'draft', label: 'Draft', level: 0 }]
      } else {
        status = [
          { value: 'draft', label: 'Draft', level: 0 },
          { value: 'open', label: 'Open', level: 1 },
          { value: 'pend', label: 'Pending', level: 2 },
          { value: 'done', label: 'Done', level: 3 },
        ]
      }
      switch (this.old_status_value) {
        case 'draft':
          //status = ['draft', 'open']
          status = [
            { value: 'draft', label: 'Draft', level: 0 },
            { value: 'open', label: 'Open', level: 1 },
            { value: 'pend', label: 'Pending', level: 2 },
            { value: 'done', label: 'Done', level: 3 },
          ]
          break
        case 'open':
          //status = ['open', 'pend', 'done']
          status = [
            { value: 'draft', label: 'Draft', level: 0 },
            { value: 'open', label: 'Open', level: 1 },
            { value: 'pend', label: 'Pending', level: 2 },
            { value: 'done', label: 'Done', level: 3 },
          ]
          break
        case 'pend':
          //status = ['pend', 'open', 'done']
          status = [
            { value: 'draft', label: 'Draft', level: 0 },
            { value: 'open', label: 'Open', level: 1 },
            { value: 'pend', label: 'Pending', level: 2 },
            { value: 'done', label: 'Done', level: 3 },
          ]
          break
        case 'done':
          //status = ['done']
          status = [
            { value: 'draft', label: 'Draft', level: 0 },
            { value: 'open', label: 'Open', level: 1 },
            { value: 'pend', label: 'Pending', level: 2 },
            { value: 'done', label: 'Done', level: 3 },
          ]
          break
        default:
          //status.push('draft')
          status = [{ value: 'draft', label: 'Draft', level: 0 }]
          break
      }
      this.list_status = status
    },
    */
  },
  validations() {
    return {
      form: {
        supplier: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
        inv_no: {
          required: helpers.withMessage('No Invoice harus diisi.', required),
        },
        golongan: {
          required: helpers.withMessage('Golongan harus diisi.', required),
        },
      },
    }
  },
}
</script>
